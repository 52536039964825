/* *{
  padding: 0;
  margin: 0;
} */
body {
  height: 100% !important;
}
.card {
  width: 100% !important;
  margin: 1.66rem 1.66rem 0 1.66rem;
  min-height: 100%;
}
.camoption {
  margin-top: 10px !important;
}
.card {
  padding-bottom: 5%;
}
.embed_button {
  width: 50%;
  margin-top: 14px !important;
  margin-left: 20% !important;
}
.live-video {
  position: absolute;
  top: 2%;
  left: 8%;
  color: #f1f1f1 !important;
}
.popular-live-video {
  position: absolute;
  top: 7%;
  left: 6%;
  color: #f1f1f1 !important;
}
.video_big {
  width: 100%;
}
.video_card {
  width: 90% !important;
  padding: 20px !important;
}
.video_card_live {
  width: 90% !important;
  padding: 20px !important;
}
.video_save_card {
  width: 90% !important;
  padding: 20px !important;
}
.video_grid {
  object-fit: cover;
  height: 190px !important;
  width: 100% !important;
  border-radius: 10px;
}
.video_row {
  display: flex;
}
.main_video {
  position: relative;
}
.text-block {
  position: absolute;
  bottom: 10%;
  left: 15%;
  color: white;
}
.commonclr {
  color: #019dda !important;
}
.heading {
  font-weight: bold;
  font-size: 1.4rem;
  color: #019dda !important;
  padding: 0px 0px 12px 0px !important;
}
.commonred {
  color: #fe0000 !important;
}
.btn {
  color: white !important;
  background-color: #019dda !important;
}
.backred {
  color: white !important;
  background-color: #fe0000 !important;
}
.small-video-container {
  width: 100%;
  margin: auto;
  max-width: 100%;
}
.shaka-video {
  width: 100%;
  height: 100%;
  background-color: black !important;
}
.sidebar_button {
  padding: 10px;
  color: #fe0000 !important;
}
.go_live {
  padding: 10px;
  background-color: #bad0ec !important;
  border-radius: 5px;
}
.sidebar_button:hover {
  background-color: #f2f2f2 !important;
  border-radius: 5px;
  cursor: pointer;
}
.icon_color {
  color: #019dda !important;
}
.grid_size {
  margin: 0px 10px 45px 10px !important;
}
.grid_background {
  background-color: #f2f2f2 !important;
  margin-bottom: 4rem !important;
}
.live {
  padding: 15px !important;
}
.live:hover {
  background-color: #f2f2f2 !important;
  width: 100% !important;
  padding: 15px !important;
  border-radius: 5px !important;
}
.dropdown_option {
  padding: 10px !important;
}
.dropdown_option:hover {
  background-color: #f2f2f2 !important;
  width: 100% !important;
  padding: 10px !important;
  border-radius: 5px !important;
}
.nav-bar {
  position: fixed !important;
  overflow-y: scroll;
  overflow-x: hidden !important;
  overflow: auto !important;
  height: 100%;
  padding-left: 2% !important;
  box-shadow: 2px 0 5px -2px #888;
  min-width: 25.8% !important;
}
.gridtwo {
  background-color: #e9eaee;
  margin-top: 10px !important;
  margin-left: 25.4%;
}
.main_grid {
  height: 100%;
}
#root {
  height: 100%;
}
.remote_big_screen {
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
  height: 100vh;
  background-color: black;
}
.shaka-bottom-controls {
  width: 90% !important;
}
.size {
  font-size: 17px !important;
  color: #019dda !important;
}
.text_size {
  font-size: 15px !important;
  color: #019dda !important;
}
.sidebar-title {
  color: #019dda !important;
  margin-left: 15px !important;
  margin-top: 15px !important;
}
.video_grid_column {
  padding: 4px !important;
  margin-bottom: 10px !important;
}
.video_grid:hover {
  background-color: #b4b1b1 !important;
  opacity: 0.6;
}
.user_img:before {
  padding: 10px !important;
}
.video-layout {
  width: 100%;
  height: 100vh !important;
  margin-bottom: 0 !important;
  /* margin: auto; */
  max-width: 100%;
}
.video-content {
  position: absolute;
  top: calc(2% + 10px);
  color: #f1f1f1 !important;
  z-index: 1 !important;
}
.big_screen {
  background-color: black;
  min-height: 100% !important;
}
.play-icon {
  position: absolute;
  top: 25%;
  left: 41%;
  visibility: hidden;
  color: #f1f1f1 !important;
}
.video_grid_column:hover .play-icon {
  visibility: visible !important;
  z-index: 1 !important;
}
.play-video {
  position: absolute;
  top: 40%;
  left: 45%;
  color: #f1f1f1 !important;
}
.main_recorded_video {
  position: relative;
}
.play-recorded-icon {
  position: absolute;
  top: 24%;
  left: 38%;
  color: #f1f1f1 !important;
}
.video_giant {
  margin: 0px !important;
  padding: 0px !important;
  background-color: black;
  height: 100vh;
}
.publish_Video {
  width: 86% !important;
  background-color: black;
  border-radius: 0px !important;
}
#goDown {
  position: absolute;
  bottom: 4%;
  z-index: 99;
  font-size: 30px;
  background-color: #f2f3f5;
  color: black;
  cursor: pointer;
  padding: 15px 35px 35px 15px;
  left: 48%;
}
#goDown:hover {
  background-color: #dcdcdc !important;
  cursor: pointer;
  padding: 15px 35px 35px 15px;
  border-radius: 20px;
  left: 48%;
}
.active_menu {
  background-color: #bad0ec !important;
  padding: 10px !important;
  border-radius: 5px;
  color: #fe0000 !important;
}
.post {
  position: fixed !important;
  height: 100% !important;
  overflow-y: scroll;
  overflow-x: hidden !important;
  overflow: auto;
  padding: 0px !important;
  width: 25%;
}
.navbar-menu {
  width: 100%;
  padding: 0px !important;
}
.scroll_wrap {
  background-color: #f2f2f2;
  margin-left: 25%;
}
@-webkit-keyframes fade-in-up {
  0% {
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-up {
  0% {
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
.video-wrap {
  text-align: center;
}
.video_card_err {
  width: 90% !important;
  padding: 20px !important;
}
.stuck {
  position: fixed;
  bottom: 8%;
  right: 20px;
  transform: translateY(100%);
  width: 250px;
  height: 150px;
  animation: fade-in-up 0.25s ease forwards;
  z-index: 1000;
}
.scroll_btn {
  position: absolute;
  bottom: 70%;
  right: 35px;
  z-index: 1;
  color: white;
  visibility: hidden;
}
.stuck:hover .scroll_btn {
  visibility: visible !important;
  z-index: 1 !important;
}
.user_icon {
  display: flex;
}
.grid-loader {
  min-width: 100%;
  margin: 0.1112em !important;
}
.grid_placeholder {
  width: 35% !important;
  display: flex;
}

.video-js .vjs-picture-in-picture-control {
  display: none;
}

.ui.modal > .close {
  position: sticky !important;
  float: right;
  margin: 15px 15px 0px 0px;
  color: gray !important;
}
@media screen and (max-width: 767px) {
  .ui.modal > .close {
    position: relative !important;
    float: right;
    margin: 15px 15px 0px 0px;
    color: gray !important;
  }
  .video_card {
    width: 90% !important;
    padding: 20px !important;
  }
  .post {
    position: relative !important;
    width: 100% !important;
    height: auto !important;
    padding-bottom: 0px;
    overflow-y: unset;
    overflow-x: unset;
  }
  .outerPost {
    width: 100% !important;
    padding: 15px;
  }
  .nav-bar {
    position: relative !important;
    height: auto !important;
  }
  .menu-panel {
    width: 100% !important;
  }
  .grid_size {
    margin: 0px 10px 15px 10px !important;
  }
  .setting {
    padding-bottom: 0px !important;
  }
  .publish_Video {
    width: 90% !important;
    object-fit: cover;
    height: 30vh;
    padding-left: 0px;
    border-radius: 0px !important;
  }
  .scroll_btn {
    position: absolute;
    bottom: 72%;
    right: 40px;
    z-index: 1;
    color: white;
    visibility: hidden;
  }
  .video_giant {
    height: 40vh;
  }
  .ui[class*="three column"].grid > .column:not(.row),
  .ui[class*="three column"].grid > .row > .column {
    width: 100% !important;
  }
  .play-recorded-icon {
    z-index: 1;
    transform: translate(-50%, -50%);
    margin: auto;
    position: absolute;
    top: 46%;
    right: 0;
    font-size: 6px;
    left: 60%;
    color: #f1f1f1 !important;
  }
  /* .side-nav {
    position: relative !important;
    width: 100% !important;
  }
  .gridtwo {
    min-height: auto !important;
  } */
  .play-video {
    left: 27%;
  }
  .save_screen-big {
    padding-top: 0px !important;
    width: 100vw !important;
    max-width: 100vw !important;
    height: 76vh !important;
  }
  .outerlive {
    width: 100%;
  }
  .vjs-theme-city .vjs-play-control {
    flex: 1 1 !important;
  }
}
/* .started_grid {
  height: 90vh;
} */
.screen_grid {
  height: 100%;
  margin: 0px !important;
}
.video_g1 {
  height: 100% !important;
}
.savevideo_wrpper {
  padding: 0px !important;
}
.save_contain {
  margin: 0px !important;
}
.video-error {
  position: fixed;
  top: 22px;
  left: 43%;
  z-index: 1 !important;
}
.video-js .vjs-control {
  outline: none !important;
}
i.tiny.icon,
i.tiny.icons {
  font-size: 1.1em !important;
  margin-bottom: 3px;
}
.ui.accordion .accordion .title .dropdown.icon,
.ui.accordion .title .dropdown.icon {
  position: relative !important;
  color: #0ea1ba;
}
.ui.indeterminate.text.centered.inline.loader {
  margin: auto;
  left: 50%;
  top: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
}
.ui.inverted.dimmer {
  position: fixed !important;
}
.save-video-dimensions.vjs-fluid {
  padding-top: 36% !important;
}
.video-js .vjs-tech {
  object-fit: contain;
}
.outerPost {
  width: 25%;
}
.side-nav {
  position: fixed;
}
.sidebar-padding {
  padding: 10px !important;
}
.text-grid {
  padding-top: 2% !important;
}
.save_wrapper {
  display: flex !important;
  justify-content: center;
}
@media only screen and (min-width: 768px) {
  .ui[class*="three column"].grid .column:not(.row),
  .ui[class*="three column"].grid .row > .column {
    width: 100% !important;
  }
  .play-recorded-icon {
    z-index: 1;
    transform: translate(-50%, -50%);
    margin: auto;
    position: absolute;
    top: 46%;
    right: 0;
    font-size: 12px;
    left: 59%;
    color: #f1f1f1 !important;
  }
  .main-live-grid {
    height: 78vh !important;
    width: 100% !important;
  }
  .side-nav {
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    overflow: auto;
    padding-bottom: 55px;
  }
  .outerlive {
    width: 25%;
  }
  .go_live {
    padding: 6px 6px !important;
  }
  .post {
    position: fixed !important;
    width: 100% !important;
    height: auto;
    padding-bottom: 0px;
    overflow-y: scroll;
    overflow-x: unset;
    overflow: auto;
  }
  .scroll_wrap {
    background-color: #f2f2f2;
    margin-left: 25% !important;
  }
  .gridtwo {
    background-color: #e9eaee;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    margin-left: 25.4% !important;
  }
  .ui.modal > .close {
    position: relative !important;
    float: right;
    margin: 5px 5px 0px 0px;
    color: gray !important;
  }
  .nav-bar {
    min-width: 26% !important;
  }
}
@media only screen and (min-width: 992px) {
  .ui[class*="three column"].grid .column:not(.row),
  .ui[class*="three column"].grid .row > .column {
    width: 33.333333% !important;
  }
  .play-recorded-icon {
    z-index: 1;
    transform: translate(-50%, -50%);
    margin: auto;
    position: absolute;
    top: 46%;
    right: 0;
    font-size: 12px;
    left: 62%;
    color: #f1f1f1 !important;
  }
  .side-nav {
    position: fixed;
    overflow-y: scroll !important;
    overflow-x: hidden;
    overflow: auto;
  }
  .post {
    position: fixed !important;
    height: 100% !important;
    overflow-y: scroll;
    overflow-x: hidden !important;
    overflow: auto;
    padding: 0px !important;
    width: 25%;
  }
  .ui.modal > .close {
    position: sticky !important;
    float: right;
    margin: 15px 15px 0px 0px;
    color: gray !important;
  }
}
